/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";
/* @import "~bootstrap/scss/bootstrap.scss"; */
/* @import '@angular/material/*'; */
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/prism/1.16.0/themes/prism.css";

.mat-mdc-tab-group.mat-primary .mdc-tab-indicator__content--underline,
.mat-tab-nav-bar.mat-primary .mdc-tab-indicator__content--underline {
  background-color: #fec40d;
  border-color: #fec40d !important;
  z-index: 3 !important;
  height: 2px;
}

/*부트스트랩 그리드 safari 대응*/
.row:before,
.row:after {
  display: inline-block !important;
}

::selection {
  background-color: rgba(254, 198, 13, 0.7);
}

* {
  font-family: noto sans kr;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
   font-family: "Noto Sans KR";
}

.inert-fallback {
  pointer-events: none;
  user-select: none;
}

/* loading spinner */
.loading-spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px; /* Adjust based on your modal size */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1); /* Light border */
  border-left-color: #fec40d; /* Spinner color */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* homemade shorthand properties */
.height-auto {
  height: auto !important;
}

.height-100 {
  height: 100% !important;
}

.width-100 {
  width: 100% !important;
}

.width-auto {
  width: auto !important;
}

.width-90 {
  width: 90% !important;
}

.width-75 {
  width: 75% !important;
}

.width-50 {
  width: 50% !important;
}

.width-40 {
  width: 40% !important;
}

.width-33 {
  width: 33% !important;
}

.width-25 {
  width: 25% !important;
}

.border-none {
  border: none !important;
}

.margin-y-1px {
  margin: 1px 0 !important;
}

.margin-y-2px {
  margin: 2px 0 !important;
}

.margin-y-3px {
  margin: 3px 0 !important;
}

.margin-y-5px {
  margin: 5px 0 !important;
}

.margin-top-none {
  margin-top: 0 !important;
}

.margin-top-2 {
  margin-top: 2px !important;
}

.margin-top-3 {
  margin-top: 3px !important;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-top-30 {
  margin-top: 30px !important;
}

.margin-left-none {
  margin-left: 0 !important;
}

.margin-left-3 {
  margin-left: 3px !important;
}

.margin-left-5 {
  margin-left: 5px !important;
}

.margin-left-10 {
  margin-left: 10px !important;
}

.margin-left-15 {
  margin-left: 15px !important;
}

.margin-left-20 {
  margin-left: 20px !important;
}

.margin-right-none {
  margin-right: 0 !important;
}

.margin-right-3 {
  margin-right: 3px;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.margin-right-20 {
  margin-right: 20px !important;
}

.margin-bottom-none {
  margin-bottom: 0 !important;
}

.margin-bottom-2 {
  margin-bottom: 2px !important;
}

.margin-bottom-3 {
  margin-bottom: 3px !important;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.padding-none {
  padding: 0 !important;
}

.padding-top-none {
  padding-top: 0 !important;
}

.padding-bottom-none {
  padding-bottom: 0 !important;
}

.padding-bottom-10 {
  padding-bottom: 10px !important;
}

.padding-left-7 {
  padding-left: 7px !important;
}

.padding-right-10 {
  padding-right: 10px !important;
}

.padding-x-20 {
  padding: 0 20px !important;
}

::ng-deep .padding-x-3pct {
  padding: 0 3% !important;
}

.height-30 {
  height: 30px !important;
}

.min-width-1320 {
  min-width: 1320px !important;
}

.search-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  margin-top: 15px;
}

.search-not-found-md {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  margin-top: 15px;
}

.search-not-found-lg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
  margin-top: 15px;
}

.search-not-found h2, .search-not-found-md h2, .search-not-found-lg h2 {
  align-items: center;
  font-weight: 600;
}

.col-flex {
  display: flex;
  flex-direction: column !important;
  justify-content: center !important;
}
